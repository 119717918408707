<template>
  <div class="create-speaker-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'Speaker' }"
        >講者管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>新增講者</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="speakerForm"
        :model="speaker_form"
        :rules="speaker_rules"
        label-position="top"
      >
        <el-form-item label="講者頭像" prop="avatar_file">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="handleUpload"
            :before-upload="handleBeforeAvatarUpload"
            accept="image/jpeg,image/jpg,image/png"
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="avatar"
              alt="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-button type="danger" size="small" @click="handleClearAvatar"
            >清除圖片</el-button
          >
        </el-form-item>
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="speaker_form.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="speaker_form.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="中文介紹" prop="introduction_zh">
          <el-input
            type="textarea"
            v-model="speaker_form.introduction_zh"
            rows="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文介紹" prop="introduction_en">
          <el-input
            type="textarea"
            v-model="speaker_form.introduction_en"
            rows="8"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createSpeaker } from "@/api/activity-speaker";

export default {
  name: "CreateSpeaker",
  data() {
    return {
      speaker_form: {
        avatar_file: "",
        name_zh: "",
        name_en: "",
        introduction_zh: "",
        introduction_en: "",
      },
      speaker_rules: {
        name_zh: [
          { required: true, message: "請輸入中文姓名", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入英文名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        introduction_zh: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
        introduction_en: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
      },
      preview_url: null,
    };
  },
  methods: {
    handleBeforeAvatarUpload(file) {
      const imageType = ["image/jpeg", "image/jpg", "image/png"];

      if (imageType.indexOf(file.type) === -1) {
        this.$message.error("請上傳jpg, jpeg, png的格式");
        return false;
      }

      return true;
    },
    handleUpload(file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.preview_url = reader.result;
      });

      if (file) {
        reader.readAsDataURL(file.file);
        this.speaker_form.avatar_file = file.file;
      }
    },
    handleSubmit() {
      this.$refs.speakerForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();

          Object.keys(this.speaker_form).forEach((key) => {
            formData.append(key, this.speaker_form[key]);
          });

          await createSpeaker(formData);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    handleClearAvatar() {
      this.preview_url = "";
      this.speaker_form.avatar_file = null;
    },
    async handleRedirect() {
      await this.$router.push({ name: "Speaker" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }
}
::v-deep.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
